import {PDF_VIEWER} from "../../constant";
import {Component, HostListener} from "@angular/core";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Component(
    {
        selector: 'williams-ui-platform-nav-bar-discovery',
        templateUrl: './nav-bar.component.html',
        styleUrls: ['./nav-bar.component.scss']
    }
)
export class NavBarComponent_ {
    menus = [
        {
            text: "Capacity",
            selected: true,
            title: "Capacity",
            id: 1,
        },
        {
            text: "Operationally Available",
            id: 2,
            urlPath: "capacity",
            parentId: 1,
        },
        {
            text: "Unsubscribed",
            id: 3,
            urlPath: "capacityhtml/unsubscribed",
            parentId: 1,
        },
        {
            text: "Capacity Allocation Log",
            id: 4,
            urlPath: "capacityhtml/cap_alloc_log",
            parentId: 1,
        },
        {
            text: "Gas Quality",
            id: 5,
            selected: true,
        },
        {
            id: 6,
            text: "Tariff Provisions",
            parentId: 5,
            type: 'pdf',
            urlPath: `${PDF_VIEWER}?fileName=discovery_trf.pdf&title=specs`
        },
        {
            id: 7,
            text: "Flowing Gas",
            parentId: 5,
            urlPath: "gas-quality"

        },
        {
            id: 8,
            text: "Gas Sampling Procedures",
            parentId: 5,
            urlPath: "gas-qualityhtml/sampling"
        },
        {
            id: 9,
            selected: true,
            text: "Index of Customers",
            urlPath: "indexofcustomers",
        },
        {
            id: 10,
            text: "Locations",
            selected: true,
            urlPath: "locations",
        },
        {
            id: 11,
            text: "Notices",
            selected: true,
        },
        {
            id: 12,
            text: "Critical",
            parentId: 11,
            urlPath: "notices",
        },
        {
            id: 13,
            text: "Non-Critical",
            parentId: 11,
            urlPath: "notices/non-critical-notices",
        },
        {
            id: 14,
            text: "Planned Service Outage",
            parentId: 11,
            urlPath: "notices/planned-service-outages",
        },
        {
            id: 15,
            text: "Request to Purchase Releasable Capacity",
            parentId: 11,
            urlPath: "notices/request-to-purchase",
        },
        {
            id: 16,
            text: "Waste Heat Recovery",
            parentId: 11,
            urlPath: "noticeshtml/recovery",
        },
        {
            id: 17,
            text: "NAESB Waiver",
            parentId: 11,
            urlPath: "noticeshtml/waiver",
        },
        {
            id: 18,
            text: "Posted Imbalances",
            urlPath: "postedImbalances?id=posted",
            selected: true,
        },
        {
            id: 19,
            text: "Standard of Conduct",
            selected: true,
        },
        {
            id: 20,
            text: "Affiliate Names & Addresses",
            parentId: 19,
            urlPath: "standard-of-conduct/nme_addr",
        },
        {
            id: 21,
            text: "Affiliate Tariff Waivers",
            parentId: 19,
            urlPath: "standard-of-conduct/trf_disc_actn",
        },
        {
            id: 22,
            text: "Chief Compliance Officer",
            parentId: 19,
            urlPath: "standard-of-conduct/comp_ofcr",
        },
        {
            id: 23,
            text: "Employee Transfers",
            parentId: 19,
            urlPath: "standard-of-conduct/emp_xfers",
        },
        {
            id: 24,
            text: "Implementation Procedures",
            parentId: 19,
            urlPath: "standard-of-conduct/impl_proc",
        },
        {
            id: 25,
            text: "Information Disclosure",
            parentId: 19,
            urlPath: "standard-of-conduct/info_disc",
        },
        {
            id: 26,
            text: "Job Titles & Description",
            parentId: 19,
            urlPath: "standard-of-conduct/job_desc",
        },
        {
            id: 261,
            text: "Potential Mergers",
            parentId: 19,
            urlPath: "standard-of-conduct/potn_mrgrs",
        },
        {
            id: 262,
            text: "Shared Facilities",
            parentId: 19,
            urlPath: "standard-of-conduct/shrd_fclt",
        },
        {
            id: 263,
            text: "Voluntary Consent",
            parentId: 19,
            urlPath: "standard-of-conduct/vol_const_disc",
        },
        {
            id: 27,
            text: "Tariff",
            selected: true,
        },
        {
            id: 28,
            text: "Title Page",
            parentId: 27,
            type: 'pdf',
            urlPath: `${PDF_VIEWER}?fileName=discovery_trf.pdf&title=title`
        },
        {
            id: 29,
            text: "Table of Contents",
            parentId: 27,
            type: 'pdf',
            urlPath: `${PDF_VIEWER}?fileName=discovery_trf.pdf&title=toc`
        },
        {
            id: 30,
            text: "Preliminary Statement",
            parentId: 27,
            type: 'pdf',
            urlPath: `${PDF_VIEWER}?fileName=discovery_trf.pdf&title=prelim`
        },
        {
            id: 31,
            text: "Map",
            parentId: 27,
            type: 'image',
            urlPath: `system-map`
        },
        {
            id: 32,
            text: "Currently Effective Rates",
            parentId: 27,
            type: 'pdf',
            urlPath: `${PDF_VIEWER}?fileName=discovery_trf.pdf&title=rates`
        },
        {
            id: 33,
            text: "Rate Schedules",
            parentId: 27,
            type: 'pdf',
            urlPath: `${PDF_VIEWER}?fileName=discovery_trf.pdf&title=ratesched`
        },
        {
            id: 34,
            text: "General Terms and Conditions",
            parentId: 27,
            type: 'pdf',
            urlPath: `${PDF_VIEWER}?fileName=discovery_trf.pdf&title=gtc`
        },
        {
            id: 35,
            text: "Form of Service Agreement",
            parentId: 27,
            type: 'pdf',
            urlPath: `${PDF_VIEWER}?fileName=discovery_trf.pdf&title=fosa`,
        },
        {
            id: 36,
            text: "Entire Tariff",
            parentId: 27,
            type: 'pdf',
            urlPath: `${PDF_VIEWER}?fileName=discovery_trf.pdf`,
        },
        {
            id: 37,
            text: "Sheet/Selection Index",
            parentId: 27,
            type: 'pdf',
            urlPath: `${PDF_VIEWER}?fileName=discovery_trf.pdf&title=tsindex`,
        },
        {
            id: 38,
            text: "Search Tariff",
            parentId: 27,
            urlPath: "/tariff/search"
        },
        {
            id: 39,
            text: "Transactional Reporting",
            selected: true,
        },
        {
            id: 40,
            text: "Firm",
            parentId: 39,
            urlPath: "transactionalReporting/trans_firm",
        },
        {
            id: 41,
            text: "Interruptible",
            parentId: 39,
            urlPath: "transactionalReporting/trans_inter",
        },
        {
            id: 49,
            selected: true,
            text: "Contacts",
            urlPath: "contacts",
        },
        {
            id: 42,
            text: "Downloads",
            selected: true,
            urlPath: "downloads",
        },
        {
            id: 44,
            text: "Search",
            selected: true,
            urlPath: "search?id=search",
        },

        {
            id: 46,
            selected: true,
            text: "Customer Activities",
        },
        {
            id: 50,
            selected: true,
            parentId: 46,
            type: 'pdf',
            urlPath: "https://gaskit.williams.com",
            text: "GasKit",
        },
        {
            id: 48,
            text: "Site Map",
            selected: true,
            urlPath: "site-map",
        },
    ];

    activeParentId: number | null = null;
    isChildContainerOpen: boolean = false;

    constructor(private router: Router) {}

    hasChildren(parentId: number): boolean {
        return this.menus.some(item => item.parentId === parentId);
    }


    toggleChildren(item: any): void {
        if (this.hasChildren(item.id)) {
            this.activeParentId = this.activeParentId === item.id ? null : item.id;
            this.isChildContainerOpen = this.activeParentId !== null;
        } else {
            this.onSelect(item);
            this.closeChildNavBar();
        }
        const isBlurred = this.activeParentId !== null;
        document.querySelector('.toggleblur')?.classList.toggle('blur-background', isBlurred);
    }

    onSelect(item: any) {
        if (item.urlPath && item?.type !== 'pdf') {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            this.router.navigateByUrl(`${item.urlPath}`);
        } else if (item?.type === 'pdf') {
            console.log(item.urlPath);
            window.open(`${item.urlPath}`, '_blank');
        } else if (item?.type === 'image'){
            console.log(item.urlPath);
            window.open(`${item.urlPath}`, '_blank');
        }
        this.closeChildNavBar();
    }

    closeChildNavBar(): void {
        this.activeParentId = null;
        this.isChildContainerOpen = false;
        document.querySelector('.toggleblur')?.classList.remove('blur-background');
    }

    getChildren(parentId: number): any[] {
        return this.menus.filter(item => item.parentId === parentId);
    }

    shouldShowContacts(): boolean {
        if(environment.showContacts === 'true') {
            return true;
        }
        return false;
    }

    //This is if you wanted one off
    // filterMenus(): void {
    //     const showContacts = this.shouldShowContacts();
    //     const filteredMenus = this.menus.filter(menu => {
    //         if (menu.text === 'Contacts' && !showContacts) {
    //             return false;
    //         }
    //         return menu.selected === true;
    //     });
    //     const parentIds = filteredMenus.map(menu => menu.id);
    //     this.menus = this.menus.filter(menu => {
    //         return parentIds.includes(menu.parentId!) || filteredMenus.includes(menu);
    //     });
    // }

    ngOnInit(): void {
        document.querySelector('.toggleblur')?.addEventListener('click', () => {
            this.closeChildNavBar();
        });
    }
}

