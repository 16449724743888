import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import {
  DataStateChangeEvent,
  GridComponent as KendoGridComponent,
  SelectAllCheckboxState,
  SelectionEvent,
} from "@progress/kendo-angular-grid";
import { TooltipDirective } from "@progress/kendo-angular-tooltip";
import { aggregateBy, groupBy, process } from "@progress/kendo-data-query";
import { encodeBase64, saveAs } from "@progress/kendo-file-saver";
import { merge, Subscription, take, tap } from "rxjs";
import { DialogService } from "../../../services/dialog.service";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { numberFormatter } from "../utils";
import {
  COMMAND_COLUMN_ACTION_ITEMS,
  DEFAULT_CHECKBOX_COLUMN_CONFIGURATION,
  DEFAULT_COLUMN_CONFIGURATION,
  DEFAULT_COMMAND_COLUMN_CONFIGURATION,
  DEFAULT_EXCEL_OPTIONS,
  DEFAULT_GRID_STATE,
  DEFAULT_PDF_OPTIONS,
  GRID_ROW_NUMBER_FIELD,
} from "./constants/default-values";
import {
  CANCEL_CONFIRMATION_DIALOG_CONTENT,
  CANCEL_CONFIRMATION_DIALOG_TITLE,
  EXPORT_ERROR_TITLE,
  PDF_EXPORT_DISABLED_CONTENT,
} from "./constants/messages";
import { GridTemplatesDirective } from "./directives/grid-templates.directive";
import {
  AggregateDescriptor,
  CellClickEvent,
  CellDoubleClickEvent,
  ColumnMenuSettings,
  CommandColumnActionItem,
  CompositeFilterDescriptor,
  CopyFn,
  CreateFormGroupFn,
  DetailTemplateShowIfFn,
  ExcelOptions,
  FilterableSettings,
  GridDataResult,
  GridFormValidationResult,
  GridNavigableSettings,
  GroupableSettings,
  GroupDescriptor,
  PagerSettings,
  PDFOptions,
  QuickAddFn,
  RowArgs,
  RowClassFn,
  RowDeletableFn,
  RowEditableFn,
  RowSelectedFn,
  ScrollMode,
  SelectableSettings,
  SortSettings,
  State,
} from "./models";
import {
  CheckboxColumnConfiguration,
  ColumnConfiguration,
  CommandColumnConfiguration,
  NonPartialColumnConfiguration,
} from "./models/column";
import { GridEditService } from "./services/grid-edit.service";
import { getFieldValueFromDataItemRecursively } from "./utils";
import { exportPDF as KendoExportAsPDF } from "@progress/kendo-drawing";
import { formatNumber } from "@progress/kendo-angular-intl";
@Component({
  selector: "williams-ui-platform-extended-grid",
  templateUrl: "./extended-grid.component.html",
  providers: [GridEditService],
})
export class ExtendedGridComponent implements OnInit, OnChanges {
  // Indicates whether the Grid columns will be resized during initialization so that they fit their headers and row content.
  @Input() autoSize = false;

  @Input() showBorderForAllFooterCells = false;

  // Specifies if the column menu of the columns will be displayed.
  @Input() columnMenu: boolean | ColumnMenuSettings = true;
  @Input() preventScrollTopOnGridRefresh = true;
  // Specifies the data for the grid.
  @Input("data") set _data(data: any) {
    this._resetGrid();
    this._originalData = data.map((item: any) => {
      return {
        ...item,
        isNewItem: false,
        rowId: this._counter++,
      };
    });
    this._originalDataBackup = this._originalData.map((dataItem) => ({
      ...dataItem,
    }));
  }

  // Defines the detail row height that is used when the scrollable option of the Grid is set to virtual. Required by the virtual scrolling functionality.
  @Input() detailRowHeight = 100;

  // State of the grid.
  @Input("gridState") set _gridState(state: State) {
    this.gridState = {
      ...DEFAULT_GRID_STATE,
      ...state,
    };
  }

  // Enables the filtering of the Grid columns that have their field option set.
  @Input() filterable: FilterableSettings = "menu";

  // If set to true, the user can group the Grid by dragging the column header cells.
  @Input() groupable: boolean | GroupableSettings = true;

  // Defines the height (in pixels) that is used when the scrollable option of the Grid is set.
  @Input() height = 500;

  // Specifies if the header of the grid will be hidden.
  @Input() hideHeader = false;

  // Specifies if the loading indicator of the Grid will be displayed.
  @Input() loading = false;

  /**
   * If set to true, the user can use dedicated shortcuts to interact with the Grid.
   * By default, navigation is disabled and the Grid content is accessible in the normal tab sequence.
   */
  @Input() navigable: GridNavigableSettings = true;

  // Configures the pager of the Grid
  @Input() pageable: boolean | PagerSettings = false;

  // Defines the page size used by the Grid pager.
  @Input() pageSize = 100;

  // If set to true, the user can reorder columns by dragging their header cells.
  @Input() reorderable = true;

  // If set to true, the user can resize columns by dragging the edges (resize handles) of their header cells.
  @Input() resizable = true;

  // Defines a function that is executed for every data row in the component.
  @Input() rowClass: RowClassFn = () => "";

  // Defines the row height that is used when the scrollable option of the Grid is set to virtual. Required by the virtual scrolling functionality.
  @Input() rowHeight = 28;

  // Defines the scroll mode used by the Grid.
  @Input() scrollable: ScrollMode = "virtual";

  // Enables the row selection of the Grid.
  @Input() selectable: boolean | SelectableSettings = {
    enabled: true,
    mode: "multiple",
    checkboxOnly: true,
  };

  // Enables the sorting of the Grid columns that have their field option set.
  @Input() sortable: SortSettings = {
    mode: "single",
  };

  @Input("columnConfigurations") set _columnConfigurations(
    configurations: Partial<ColumnConfiguration>[]
  ) {
    this.columnConfigurations = configurations.map((configuration) => {
      const result = {
        ...DEFAULT_COLUMN_CONFIGURATION,
        ...configuration,
        columnGroupConfig: {
          ...DEFAULT_COLUMN_CONFIGURATION.columnGroupConfig,
          ...(configuration.columnGroupConfig ?? {}),
        },
      };
      result.subColumns = result.subColumns.map((item) => ({
        ...DEFAULT_COLUMN_CONFIGURATION,
        ...item,
      }));

      return result;
    }) as NonPartialColumnConfiguration[];
  }

  @Input("commandColumnConfiguration") set _commandColumnConfiguration(
    configuration: Partial<CommandColumnConfiguration>
  ) {
    this.commandColumnConfiguration = {
      ...DEFAULT_COMMAND_COLUMN_CONFIGURATION,
      ...configuration,
    };
  }

  @Input("checkboxColumnConfiguration") set _checkboxColumnConfiguration(
    configuration: Partial<CheckboxColumnConfiguration>
  ) {
    this.checkboxColumnConfiguration = {
      ...DEFAULT_CHECKBOX_COLUMN_CONFIGURATION,
      ...configuration,
    };
  }

  @Input() createFormGroupFn!: CreateFormGroupFn;

  @Input() quickAddFn: QuickAddFn = () => ({});

  @Input() copyFn: CopyFn = (dataItem: any) => {
    return { ...dataItem };
  };

  @Input("pdfOptions") set _pdfOptions(options: Partial<PDFOptions>) {
    this.pdfOptions = {
      ...DEFAULT_PDF_OPTIONS,
      ...options,
    };
  }

  @Input("excelOptions") set _excelOptions(options: Partial<ExcelOptions>) {
    this.excelOptions = {
      ...DEFAULT_EXCEL_OPTIONS,
      ...options,
    };
  }

  @Input() enableCellClickEdit = true;

  @Input() adjustScroll = true;

  @Input() allowMultiRowEditing = true;

  @Input() commandColumnActionItems: CommandColumnActionItem[] =
    COMMAND_COLUMN_ACTION_ITEMS;

  // Whether to show count of rows at the grid footer
  @Input() showRowCount = true;

  // The row count label, adding "'s" in case of more than one row is handled in grid
  @Input() rowCountLabel = "";

  // Disables all editing of grid, appends readonly class to grid wrapper
  @Input() readonly: boolean = false;

  @Input() rowDeletableFn: RowDeletableFn = () => true;

  @Input() rowEditableFn: RowEditableFn = () => true;

  @Input() showGridFooterTemplate = true;

  @Input() hideColumnFooterOnEmptyData = true;

  @Input() rowSelectedFn: RowSelectedFn = (rowArgs: RowArgs) => {
    return this.selectedRows.includes(rowArgs.dataItem.rowId);
  };

  @Input() autoAdjustPdfWidth = false;

  @Input() detailTemplateShowIfFn: DetailTemplateShowIfFn = () => true;

  @Input() isDetailExpandedFn!: (args: RowArgs) => boolean;

  @Output() selectionChange: EventEmitter<any> = new EventEmitter();

  @Output() validationChange: EventEmitter<GridFormValidationResult> =
    new EventEmitter();

  @Output() resetErrorsOnCancel: EventEmitter<boolean> = new EventEmitter();

  @Output() gridViewLoaded = new EventEmitter<void>();

  @Output() cellClick = new EventEmitter<CellClickEvent>();

  @Output() cellDoubleClick = new EventEmitter<CellDoubleClickEvent>();

  @ViewChild("grid") grid!: KendoGridComponent;

  @ViewChild("defaultCommandColumnHeaderTemplate")
  defaultCommandColumnHeaderTemplate!: TemplateRef<any>;

  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;

  @ContentChild(forwardRef(() => GridTemplatesDirective))
  gridTemplates!: GridTemplatesDirective;

  columnConfigurations: NonPartialColumnConfiguration[] = [];

  gridView: GridDataResult = {
    total: 0,
    data: [],
  };

  selectAllState: SelectAllCheckboxState = "unchecked";

  selectedRows: number[] = [];

  pdfOptions: PDFOptions = DEFAULT_PDF_OPTIONS;
  excelOptions: ExcelOptions = DEFAULT_EXCEL_OPTIONS;

  checkboxColumnConfiguration = DEFAULT_CHECKBOX_COLUMN_CONFIGURATION;
  commandColumnConfiguration = DEFAULT_COMMAND_COLUMN_CONFIGURATION;

  gridState = DEFAULT_GRID_STATE;
  gridColumnFooterAggregates: { [key: string]: string} = {};

  private _originalDataBackup: any[] = [];
  private _originalData: any[] = [];
  private _counter = 0;
  private _formValueChangeSubscription!: Subscription;

  constructor(
    private _editService: GridEditService,
    private _dialogService: DialogService,
    private _elementRef: ElementRef,
    private _cdRef: ChangeDetectorRef
  ) {
    this.allDataForExcelExport = this.allDataForExcelExport.bind(this);
  }

  ngOnInit(): void {
    this._initGridState();
    this._loadGridView(this.gridState);
    this._handleFormGroupChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const gridDataChange = changes["_data"];
    if (gridDataChange && !gridDataChange.isFirstChange()) {
      this._resetGrid();
      this._initGridState();
      if(this.preventScrollTopOnGridRefresh) {
        this.grid.scrollTo({row: 0});
      }
      setTimeout(() => this._loadGridView(this.gridState, this.adjustScroll));
    }
  }

  private _initGridState() {
    if (this.scrollable === "virtual") {
      this.gridState = {
        ...this.gridState,
        skip: this.gridState.skip ?? 0,
        take: this.gridState.take ?? this.pageSize,
      };
    }
    this.gridState.group = this.gridState.group?.map((group) => {
      return {
        ...group,
        aggregates: this.gridState.aggregates,
      };
    });
  }

  // Returns count of rows currently displayed (after applying filter & data operations)
  get rowCount(): number {
    return this.gridView.total;
  }

  get isGroupable(): boolean | GroupableSettings {
    return !this._isGridInEditMode && this.groupable;
  }

  private get _isGroupingApplied(): boolean {
    return (
      this.groupable &&
      this.gridState.group != undefined &&
      this.gridState.group.length > 0
    );
  }

  get allowGridEditing(): boolean {
    return !this._isGroupingApplied;
  }

  private get _isGridInEditMode(): boolean {
    return !!this.grid && this.grid.isEditing();
  }

  private get _isGridHasDeletedItems(): boolean {
    return this._editService.getDeletedItems().length > 0;
  }

  get isGridEditing(): boolean {
    return this._isGridInEditMode || this._isGridHasDeletedItems;
  }

  isRowDeletable(dataItem: any): boolean {
    return this.allowGridEditing && this.rowDeletableFn(dataItem);
  }

  isColumnFilterable(columnConfiguration: ColumnConfiguration): boolean {
    return columnConfiguration.filterable && !this._isGridInEditMode;
  }

  isColumnSortable(columnConfiguration: ColumnConfiguration): boolean {
    return columnConfiguration.sortable && !this._isGridInEditMode;
  }

  private _loadGridView(gridState: State, adjustScroll = false): void {
    this.gridView = process(this._originalData, gridState);
    this.gridViewLoaded.next();
    if(adjustScroll) {
      setTimeout(() => {
        const gridContent = this._elementRef.nativeElement.querySelector(".k-grid-content") as HTMLElement;
        if(gridContent && gridContent.scrollLeft > 0) {
          gridContent.scrollLeft = 0;
        }
      }, 500)
    }

    this._computeGridFooterAggregates();
  }

  private _computeGridFooterAggregates(): void {
    const data = process(this._originalData, {
      group: this.gridState.group,
      filter: this.gridState.filter,
      sort: this.gridState.sort,
    }).data;

    let gridData = this.gridState.group
      ? this._flattenGroupedGridViewData(data)
      : data;

    gridData = gridData.map((dataItem) => {
      const group = this._editService.getFormGroup(dataItem.rowId);
      if (group) {
        const mergedItem = {
          ...dataItem,
          ...group.getRawValue(),
        };

        return mergedItem;
      }

      return dataItem;
    });

    this.gridColumnFooterAggregates = {};
    for(const aggregateDescriptor of this.gridState.aggregates) {
      this.gridColumnFooterAggregates = {
        ...this.gridColumnFooterAggregates,
        [aggregateDescriptor.field]: this._computeColumnAggregate(aggregateDescriptor, gridData)
      }
    }
  }

  private _computeColumnAggregate(aggregateDescriptor: AggregateDescriptor, gridData: any[]): string {
    // Compute aggregate for the column
    if (gridData.length === 0) {
      return "";
    }

    const fieldName = aggregateDescriptor.field;

    const data = gridData.map(item => {
      const result = { ...item };
      result[fieldName] = result[fieldName] ? +result[fieldName] :0;
      return result;
    });

    if(aggregateDescriptor.aggregateFn) {
      return aggregateDescriptor.aggregateFn(data, aggregateDescriptor);
    }

    const aggregates = aggregateBy(data, [aggregateDescriptor]);
    if (aggregateDescriptor.numberFormat?.required) {
      return `${aggregateDescriptor.label}${numberFormatter(
        aggregates[fieldName][aggregateDescriptor.aggregate],
        aggregateDescriptor.numberFormat?.locale
      )}`;
    }

    if(aggregateDescriptor.format) {
      return `${aggregateDescriptor.label}${formatNumber(
        aggregates[fieldName][aggregateDescriptor.aggregate],
        aggregateDescriptor.format
      )}`;
    }

    return `${aggregateDescriptor.label}${
      aggregates[fieldName][aggregateDescriptor.aggregate]
    }`;
  }

  isCellEditable(
    dataItem: any,
    columnConfiguration: ColumnConfiguration
  ): boolean {
    if (dataItem.isNewItem) {
      return columnConfiguration.editableOn.add;
    }

    return columnConfiguration.editableOn.edit;
  }

  onCellClick(event: CellClickEvent) {
    const { dataItem, isEdited, rowIndex } = event;
    if (!this.enableCellClickEdit) {
      this.cellClick.emit(event);
      return;
    }

    if (
      this.readonly ||
      isEdited ||
      this._isGroupingApplied ||
      !this.rowEditableFn(dataItem)
    ) {
      return;
    }

    if (this._isGridInEditMode && !this.allowMultiRowEditing) {
      return;
    }

    this._editGridRow(dataItem, rowIndex);
  }

  // Opens the grid row at given rowIndex in edit mode (Only if rowEditableFn for the given dataItem returns true)
  public editRow(dataItem: any, rowIndex: number): void {
    if (this.rowEditableFn && this.rowEditableFn(dataItem)) {
      this._editGridRow(dataItem, rowIndex);
    }
  }

  private _editGridRow(dataItem: any, rowIndex: number) {
    const formGroup = this.createFormGroupFn(dataItem);

    this._editService.addToUpdatedItems(dataItem.rowId, formGroup);

    this.grid.editRow(rowIndex, formGroup);
  }

  private _getGridViewData(): any[] {
    if (!this.groupable) {
      return this.gridView.data;
    }

    return this._flattenGroupedGridViewData(this.gridView.data);
  }

  private _flattenGroupedGridViewData(gridViewData: any[]): any[] {
    const result = [];
    for (let item of gridViewData) {
      if (item.items) {
        result.push(...this._flattenGroupedGridViewData(item.items));
      } else {
        result.push(item);
      }
    }

    return result;
  }

  private _closeAllRows(): void {
    const data = process(this._originalData, {
      group: this.gridState.group,
      filter: this.gridState.filter,
      sort: this.gridState.sort,
    }).data;

    const gridData = this.gridState.group
      ? this._flattenGroupedGridViewData(data)
      : data;
    gridData.forEach((item, index) => {
      this.grid.closeRow(index);
    });
  }

  public closeAllRows(): void {
    this._closeAllRows();
  }

  public recalculateAggregates(): void {
    this._computeGridFooterAggregates();
  }

  private _reopenGridEditor(): void {
    this._closeAllRows();

    const data = process(this._originalData, {
      group: this.gridState.group,
      filter: this.gridState.filter,
      sort: this.gridState.sort,
    }).data;

    const gridData = this.gridState.group
      ? this._flattenGroupedGridViewData(data)
      : data;
    this._editService.getRowsInEditMode().forEach((group, rowId) => {
      const rowIndex = gridData.findIndex((item) => item.rowId === rowId);
      this.grid.editRow(rowIndex, group);
    });
  }

  onDataStateChange(event: DataStateChangeEvent): void {
    this.gridState = {
      ...event,
      aggregates: this.gridState.aggregates,
    };
    this.gridState.group = this.gridState.group?.map((group) => {
      return {
        ...group,
        aggregates: this.gridState.aggregates,
      };
    });
    this._loadGridView(this.gridState);
    this._reopenGridEditor();
  }

  /* Quick add functionality */

  private _getQuickAddItem(): any {
    return {
      ...this.quickAddFn(),
      isNewItem: true,
      rowId: this._counter++,
    };
  }

  quickAdd(count = 1): void {
    this._removeFilter();
    for (let i = 0; i < count; i++) {
      const item = this._getQuickAddItem();

      this._originalData.unshift(item);

      const formGroup = this.createFormGroupFn(item);

      this._editService.addToNewlyAddedItems(item.rowId, formGroup);
    }

    this._loadGridViewAndReopenEditor();
  }

  quickAddAtEndOfList(count = 1): void {
    this._removeFilter();
    for (let i = 0; i < count; i++) {
      const item = this._getQuickAddItem();

      this._originalData.push(item);

      const formGroup = this.createFormGroupFn(item);

      this._editService.addToNewlyAddedItems(item.rowId, formGroup);
    }

    this._loadGridViewAndReopenEditor();
  }

  private _removeFilter(): void {
    if (this.gridState.filter) {
      // Clearing filter before inserting new rows
      this.gridState = {
        ...this.gridState,
        filter: undefined,
      };
    }
  }

  public removeFilter(): void {
    this._removeFilter();
  }

  reloadGridView(): void {
    this._loadGridView(this.gridState);
  }

  private _loadGridViewAndReopenEditor(): void {
    this.gridState.skip = 0;
    this._loadGridView(this.gridState);
    this._reopenGridEditor();
  }

  /* End - Quick add functionality */

  addItems(
    items: any[],
    placement?: { rowId: number; insertAfter: boolean }
  ): void {
    const updatedItems = items.map((item) => {
      return {
        ...item,
        isNewItem: true,
        rowId: this._counter++,
      };
    });

    if (placement) {
      let index = this._originalData.findIndex(
        (item) => item.rowId === placement.rowId
      );
      if (placement.insertAfter) {
        index++;
      }
      this._originalData.splice(index, 0, ...updatedItems);
    } else {
      this._originalData.unshift(...updatedItems);
    }

    for (let item of updatedItems) {
      const formGroup = this.createFormGroupFn(item);
      this._editService.addToNewlyAddedItems(item.rowId, formGroup);
    }

    this.gridState.skip = 0;
    this._loadGridView(this.gridState);
    this._reopenGridEditor();
  }

  /* Copy functionality */

  private _getCopiedDataItem(dataItem: any): any {
    const formGroup = this._editService.getFormGroup(dataItem.rowId);

    let newItem = formGroup ? { ...dataItem, ...formGroup.getRawValue() } : dataItem;

    newItem = this.copyFn(newItem);

    return {
      ...newItem,
      isNewItem: true,
      rowId: this._counter++,
    };
  }

  copySelectedRows(): void {
    this._copyRows(this._getSelectedDataItems());
    this._resetSelection();
  }

  copyRow(dataItem: any) {
    this._copyRows([dataItem]);
  }

  private _copyRows(dataItems: any[]) {
    let itemsToCopy = dataItems.map((dataItem) =>
      this._getCopiedDataItem(dataItem)
    );

    this._originalData.unshift(...itemsToCopy);

    itemsToCopy.forEach((dataItem) => {
      const group = this.createFormGroupFn(dataItem);
      this._editService.addToNewlyAddedItems(dataItem.rowId, group);
    });

    this._loadGridView(this.gridState);
    this._reopenGridEditor();
  }

  /* End - Copy functionality */

  /* Delete Functionality */

  deleteSelectedRows(): void {
    this._deleteRows(this._getSelectedDataItems());
    this._resetSelection();
  }

  private _resetSelection(): void {
    this.selectedRows = [];
    this.onSelectAllChange("unchecked");
    this.onSelectionChange();
  }

  resetSelection(): void {
    this._resetSelection();
  }

  private _deleteRows(dataItems: any[]): void {
    this.closeAllRows();
    dataItems.forEach(({ rowId }) => {
      const index = this._originalData.findIndex(
        (dataItem) => dataItem.rowId === rowId
      );
      const deletedItem = this._originalData.splice(index, 1);

      if (this._editService.isNewlyAddedItem(rowId)) {
        this._editService.removeFromNewlyAddedItems(rowId);
        return;
      }

      this._editService.removeFromUpdatedItems(rowId);
      this._editService.addToDeletedItems(deletedItem[0]);

      // Remove from selected list
      const selectedRowIndex = this.selectedRows.find(
        (rowIndex) => rowIndex == rowId
      );
      if (selectedRowIndex) {
        this.selectedRows.splice(selectedRowIndex, 1);
      }
    });

    this.onSelectionChange();
    this.validationChange.emit(this.getValidationResult());
    this._loadGridView(this.gridState);
    this._reopenGridEditor();
  }

  deleteRow(dataItem: any) {
    this._deleteRows([dataItem]);
  }

  /* End - Delete Functionality */

  async cancelEdit(): Promise<void> {
    if (await this._askCancelConfirmation()) {
      this._onConfirmCancel();
    }
  }

  cancelEditWithoutConfirmMessage(): void {
    this._onConfirmCancel();
  }

  private _onConfirmCancel() {
    this._closeAllRows();
    this._originalData = this._originalDataBackup.map((dataItem) => ({
      ...dataItem,
    }));
    this.onSelectAllChange("unchecked");
    this._editService.resetEditState();
    this._loadGridView(this.gridState,true);
    this.resetErrorsOnCancel.emit(true);
  }

  onSelectionChange(e?:SelectionEvent): void {
  
  if (this.checkboxColumnConfiguration.hidden){
    const data = process(this._originalData, {
      group: this.gridState.group,
      filter: this.gridState.filter,
      sort: this.gridState.sort,
    }).data;
     if (e?.shiftKey && e.rangeStartRow && e.rangeEndRow) {
      const normalizedStart = Math.min(e.rangeStartRow.index, e.rangeEndRow.index);
      const normalizedEnd = Math.max(e.rangeStartRow.index, e.rangeEndRow.index);
      this.selectedRows = data.slice(normalizedStart,normalizedEnd+1).map((item) => item.rowId);
      } else if (e?.ctrlKey) {
       if(e.selectedRows && e.deselectedRows){
      const selectedKey = e.selectedRows[0]?.dataItem.rowId;
      const deselectedKey = e.deselectedRows[0]?.dataItem.rowId;
      if (selectedKey) {
          this.selectedRows.push(selectedKey);
      } else {
          this.selectedRows = this.selectedRows.filter((key:any) => key !== deselectedKey);
      }
    }
  } else {
    if(e?.selectedRows ){
      this.selectedRows = [e.selectedRows[0]?.dataItem.rowId];
    }   
  }
    
    } 
     const selectedDataItems = this._getSelectedDataItems();
     this.selectionChange.emit(selectedDataItems);
  }

  setSelectedRows(selectedFn: (dataItem: any) => boolean): void {
    const data = process(this._originalData, {
      group: this.gridState.group,
      filter: this.gridState.filter,
      sort: this.gridState.sort,
    }).data;
    const selectedRowIds: number[] = [];
    data.forEach((item) => {
      if (selectedFn(item)) {
        selectedRowIds.push(item.rowId);
      }
    });
    this.selectedRows = selectedRowIds;
    this.onSelectionChange();
  }


  selectFirstRow(): void {
    const data = process(this._originalData, {
      group: this.gridState.group,
      filter: this.gridState.filter,
      sort: this.gridState.sort
    }).data;
    if(data.length) {
      this.selectedRows = [data[0].rowId];
      this.onSelectionChange();
    }
  }
  
  /**
   *
   * @param filter
   */
  applyFilter(filter: CompositeFilterDescriptor): void {
    this.gridView = process(this._originalData, {
      group: this.gridState.group,
      filter: filter,
      sort: this.gridState.sort,
    });
    this.gridViewLoaded.next();
  }

  collapseAll() {
    const len = groupBy(this._originalData, this.gridState.group).length;
    for (let i = 0; i < len; i++) {
      this.grid.collapseGroup(i.toString());
    }
  }
  expandAll() {
    const len = groupBy(this._originalData, this.gridState.group).length;
    for (let i = 0; i < len; i++) {
      this.grid.expandGroup(i.toString());
    }
  }

  selectAllRows(): void {
    const data = process(this._originalData, {
      group: this.gridState.group,
      filter: this.gridState.filter,
      sort: this.gridState.sort,
    }).data;

    this.selectedRows = data.map((item) => item.rowId);
    this.onSelectionChange();
  }

  onSelectAllChange(checkedState: SelectAllCheckboxState): void {
    if (checkedState === 'checked') {
      const data = process(this._originalData, {
        group: this.gridState.group,
        filter: this.gridState.filter,
        sort: this.gridState.sort,
      }).data;
  
      this.selectedRows = data.map((item) => item.rowId);
      this.selectAllState = 'checked';
    } else {
      this.selectedRows = [];
      this.selectAllState = 'unchecked';
    }
  }

  onSelectedKeysChange(){
    const len = this.selectedRows.length;
    if (len === 0) {
      this.selectAllState = "unchecked";
    }
     else if (len > 0 && len < this._originalData.length) {
      this.selectAllState = "indeterminate";
    }
     else {
      this.selectAllState = "checked";
    }
  }

  selectCustomRows(key: string, value: string){
    const data = process(this._originalData, {
      group: this.gridState.group,
      filter: this.gridState.filter,
      sort: this.gridState.sort,
    }).data;
    this.selectedRows = data.filter(item => item[key].toUpperCase() !== value.toUpperCase()).map((item) => item.rowId);
    this.onSelectionChange();
  }

  clearSelectedRows(): void {
    this.selectedRows = [];
    this.onSelectionChange();
  }

  focusCell(rowIndex: number, colIndex: number) {
    this.grid.focusCell(rowIndex,colIndex);
  }

  exportAsPDF(fileName: string = "export.pdf"): void {
    if(this._originalData?.length > 100) {
      this._dialogService.openDialog({
        title: EXPORT_ERROR_TITLE,
        content: PDF_EXPORT_DISABLED_CONTENT,
        confirmAction: { text: "OK", value: "yes" },
      },ConfirmDialogComponent);
      return;
    }
    if (this.autoAdjustPdfWidth) {
      const width =
        this.grid.columns.reduce((result, column) => result + column.width, 0) +
        50;
      this.pdfOptions = {
        ...this.pdfOptions,
        paperSize: [`${width}pt`, "800pt"],
      };
    }

    setTimeout(async () => {
      const group = await this.grid.drawPDF();
      const result = await KendoExportAsPDF(group);
      saveAs(result, fileName);
    }, 0);
  }

  exportAsExcel(): void {
    this.grid.saveAsExcel();
  }

  exportAsCSV(csvFileName = "grid.csv"): void {
    const data = process(this._originalData, {
      group: this.gridState.group,
      filter: this.gridState.filter,
      sort: this.gridState.sort,
    }).data;

    const gridData = this.gridState.group
      ? this._flattenGroupedGridViewData(data)
      : data;
    const titles = this.columnConfigurations
      .map((column) => `"${column.title}"`)
      .join(",");
    const processedData = gridData.map((dataItem) =>
      this.columnConfigurations
        .map((column) => {
          return `"${getFieldValueFromDataItemRecursively(
            dataItem,
            column.field
          )}"`;
        })
        .join(",")
    );
    processedData.unshift(titles);
    const dataURI =
      "data:text/plain;base64," + encodeBase64(processedData.join("\n"));
    saveAs(dataURI, csvFileName);
  }

  /**
   * This method will be used when we want current grid data with the updated values
   * */

  getUpdatedMergedData() {
    const data = process(this._originalData, {
      group: this.gridState.group,
      filter: this.gridState.filter,
      sort: this.gridState.sort,
    }).data;

    let gridData = this.gridState.group
      ? this._flattenGroupedGridViewData(data)
      : data;
    gridData = gridData.map((item) => {
      const group = this._editService.getFormGroup(item.rowId);
      if (group) {
        return this._mergeFormValuesWithOriginalData(item.rowId, group);
      }
      return item;
    });
    return gridData;
  }

  getGroupAggregateForField(aggregates: any, fieldName: string): string {
    const item = this.gridState.aggregates.find(
      ({ field }) => field === fieldName
    );
    if (aggregates[fieldName] && item?.aggregate) {
      if (item.numberFormat?.required) {
        return `${item.label}${numberFormatter(
          aggregates[fieldName][item.aggregate],
          item.numberFormat?.locale
        )}`;
      }
      return `${item.label}${aggregates[fieldName][item.aggregate]}`;
    }
    return "";
  }

  getAggregatesForColumnFooter(fieldName: string): string {
    const aggregateDescriptor = this.gridState.aggregates.find(
      ({ field }) => field === fieldName
    );
    if (!aggregateDescriptor) {
      return "";
    }

    // Compute aggregate for the column

    const data = process(this._originalData, {
      group: this.gridState.group,
      filter: this.gridState.filter,
      sort: this.gridState.sort,
    }).data;

    const gridData = this.gridState.group
      ? this._flattenGroupedGridViewData(data)
      : data;

    if (gridData.length === 0) {
      return "";
    }

    const latestData = gridData.map((dataItem) => {
      let group = this._editService.getFormGroup(dataItem.rowId);
      if (group) {
        let mergedItem = {
          ...dataItem,
          ...group.getRawValue(),
        };

        mergedItem[fieldName] = +mergedItem[fieldName];

        return mergedItem;
      }

      return dataItem;
    });

    if(aggregateDescriptor.aggregateFn) {
      return aggregateDescriptor.aggregateFn(latestData, aggregateDescriptor);
    }

    const aggregates = aggregateBy(latestData, [aggregateDescriptor]);
    if (aggregateDescriptor.numberFormat?.required) {
      return `${aggregateDescriptor.label}${numberFormatter(
        aggregates[fieldName][aggregateDescriptor.aggregate],
        aggregateDescriptor.numberFormat?.locale
      )}`;
    }

    if(aggregateDescriptor.format) {
      return `${aggregateDescriptor.label}${formatNumber(
        aggregates[fieldName][aggregateDescriptor.aggregate],
        aggregateDescriptor.format
      )}`;
    }

    return `${aggregateDescriptor.label}${
      aggregates[fieldName][aggregateDescriptor.aggregate]
    }`;
  }

  // Returns column aggregates based on aggregate descriptor provided (after applying filter & data operations)
  getColumnAggregates(): any {
    // Compute aggregate for the column
    const data = process(this._originalData, {
      group: this.gridState.group,
      filter: this.gridState.filter,
      sort: this.gridState.sort,
    }).data;

    const gridData = this.gridState.group
      ? this._flattenGroupedGridViewData(data)
      : data;

    const latestData = gridData.map((dataItem) => {
      let group = this._editService.getFormGroup(dataItem.rowId);
      let result = dataItem;
      if (group) {
        result = {
          ...dataItem,
          ...group.getRawValue(),
        };
      }

      // Converting fields to number
      this.gridState.aggregates.forEach((aggregate) => {
        result[aggregate.field] = +result[aggregate.field];
      });

      return result;
    });

    let aggregates = aggregateBy(latestData, this.gridState.aggregates);
    this.gridState.aggregates.forEach((item) => {
      if (
        item.numberFormat?.required &&
        aggregates[item.field] &&
        aggregates[item.field][item.aggregate]
      ) {
        aggregates[item.field][item.aggregate] = numberFormatter(
          aggregates[item.field][item.aggregate],
          item.numberFormat?.locale
        );
      }
    });
    return aggregates;
  }

  private _getSelectedDataItems(): any[] {
    return this._originalData.filter((dataItem) =>
      this.selectedRows.includes(dataItem.rowId)
    );
  }

  private _askCancelConfirmation(): Promise<boolean> {
    return new Promise((resolve) => {
      this._dialogService
        .openDialog(
          {
            title: CANCEL_CONFIRMATION_DIALOG_TITLE,
            content: CANCEL_CONFIRMATION_DIALOG_CONTENT,
            cancelAction: { text: "Keep working", value: "no" },
            confirmAction: { text: "Cancel changes", value: "yes" },
          },
          ConfirmDialogComponent
        )
        .result.pipe(
          take(1),
          tap((result: any) => {
            resolve(result.value === "yes");
          })
        )
        .subscribe();
    });
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (
      (element.classList.contains("k-column-title") ||
        element.hasAttribute("show-tooltip")) &&
      element.offsetWidth < element.scrollWidth
    ) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  handleCommandActionClick(event: CommandColumnActionItem, dataItem: any) {
    switch (event.action) {
      case "copy":
        this.copyRow(dataItem);
        break;
      case "delete":
        this.deleteRow(dataItem);
        break;
    }
  }

  getEditedData() {
    const addedItems = Array.from(
      this._editService.getNewlyAddedItems().values()
    ).map((group) => group.getRawValue());
    const updatedItemsMap = this._editService.getUpdatedItems();
    const updatedItems: any[] = [];
    updatedItemsMap.forEach((group, rowId) => {
      updatedItems.push(this._mergeFormValuesWithOriginalData(rowId, group));
    });

    const validationResult = this.getValidationResult();

    this._subscribeToFormValueChanges();

    return {
      addedItems,
      updatedItems,
      deletedItems: this._editService.getDeletedItems(),
      validationResult,
    };
  }

  getAddedItems(): any[] {
    const addedItemsMap = this._editService.getNewlyAddedItems();
    const addedItems: any[] = [];
    addedItemsMap.forEach((group, rowId) => {
      const dataItem = this._originalData.find((item) => item.rowId === rowId);
      addedItems.push({
        ...dataItem,
        ...group.getRawValue(),
      });
    });

    return addedItems;
  }

  private _mergeFormValuesWithOriginalData(
    rowId: number,
    formGroup: FormGroup
  ): any {
    const dataItem = this._originalData.find((item) => item.rowId === rowId);
    return {
      ...dataItem,
      ...formGroup.getRawValue(),
    };
  }

  editAllRows(): void {
    const data = process(this._originalData, {
      group: this.gridState.group,
      filter: this.gridState.filter,
      sort: this.gridState.sort,
    }).data;

    const gridData = this.gridState.group
      ? this._flattenGroupedGridViewData(data)
      : data;

    gridData.forEach((dataItem, rowIndex) => {
      if (this.rowEditableFn(dataItem)) {
        this._editGridRow(dataItem, rowIndex);
      }
    });

    this.gridState.skip = 0;
    this._loadGridView(this.gridState);
  }

  private _resetGrid(): void {
    this._closeAllRows();
    this._editService.resetEditState();
    this._resetSelection();
  }

  getValidationResult(): GridFormValidationResult {
    const formGroupMaps = this._editService.getRowsInEditMode();
    const formEntries = Array.from(formGroupMaps.entries());

    const isValid = formEntries.every(([rowId, group]) => group.valid);
    return {
      isValid,
      errors: isValid
        ? []
        : this._mapFormErrors(formEntries, this.columnConfigurations),
    };
  }
  disableGridForm(): void {
    const formGroupMaps = this._editService.getRowsInEditMode();
    const formEntries = Array.from(formGroupMaps.entries());
    formEntries.forEach(([rowId, group]) => group.disable());
  }

  enableGridForm(): void {
    const formGroupMaps = this._editService.getRowsInEditMode();
    const formEntries = Array.from(formGroupMaps.entries());
    formEntries.forEach(([rowId, group]) => group.enable());
  }

  markAllAsTouched(): void {
    const formGroupMaps = this._editService.getRowsInEditMode();
    const formEntries = Array.from(formGroupMaps.entries());

    formEntries.forEach(([rowId, group]) => {
      group.markAllAsTouched();
    });
  }

  private _mapFormErrors(
    formEntries: [number, FormGroup<any>][],
    columnConfiguratins: ColumnConfiguration[]
  ): any {
    const fields = this._getEditableFieldList(columnConfiguratins);

    return formEntries
      .filter(([rowId, group]) => group.invalid)
      .map(([rowId, group]) =>
        fields.reduce(
          (result: any, controlName: string) => {
            return {
              ...result,
              [controlName]: group.get(controlName)?.errors,
            };
          },
          { rowId }
        )
      );
  }

  private _getFlatColumnConfiguraions(columnConfiguratins: ColumnConfiguration[]) {
    const result = [];
    for (const item of columnConfiguratins) {
      if (item.subColumns && item.subColumns.length > 0) {
        result.push(...this._flattenGroupedGridViewData(item.subColumns));
      } else {
        result.push(item);
      }
    }

    return result;
  }

  private _getEditableFieldList(
    columnConfiguratins: ColumnConfiguration[]
  ): any {
    const flattenedConfigurations = this._getFlatColumnConfiguraions(columnConfiguratins);
    return flattenedConfigurations
      .filter((column) => column.editableOn.add || column.editableOn.edit)
      .map((column) => column.formControlName);
  }

  private _handleFormGroupChange(): void {
    this._editService.getFormGroupChange().subscribe(() => {
      this._subscribeToFormValueChanges();
    });
  }

  private _subscribeToFormValueChanges() {
    if (this._formValueChangeSubscription) {
      this._formValueChangeSubscription.unsubscribe();
    }

    const valueChanges = Array.from(
      this._editService.getRowsInEditMode().values()
    ).map((group) => group.valueChanges);

    this._formValueChangeSubscription = merge(...valueChanges).subscribe(() => {
      this.validationChange.emit(this.getValidationResult());
      this._computeGridFooterAggregates();
    });
  }

  getCommandColumnActionItems(dataItem: any): CommandColumnActionItem[] {
    return this.commandColumnActionItems.filter((item) => {
      if (item.action === "delete") {
        return this.rowDeletableFn(dataItem);
      }

      if (item.action === "edit") {
        return this.rowEditableFn(dataItem);
      }

      return true;
    });
  }

  getAppliedGroups(): GroupDescriptor[] {
    return this.gridState.group ?? [];
  }

  allDataForExcelExport(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this._originalData, {
        group: this.gridState.group,
        sort: this.gridState.sort,
        filter: this.gridState.filter,
      }).data.map((item, index) => {
        return {
          ...item,
          [GRID_ROW_NUMBER_FIELD]: index + 1,
        };
      }),
      group: this.gridState.group,
    };
    return result;
  }

  getGridDataLength(): number {
    return this.gridView.total;
  }

  onCellDoubleClick(
    dataItem: any,
    column: ColumnConfiguration,
    rowIndex: number
  ) {
    this.cellDoubleClick.emit({ dataItem, column, rowIndex });
  }
  
  expandRow(index: number): void {
    if(this.grid) {
      this.grid.expandRow(index);
    }
  }

  ngOnDestroy(): void {
    if (this._formValueChangeSubscription) {
      this._formValueChangeSubscription.unsubscribe();
    }
  }
}
