import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WUiAngularModule } from '@williams-ui-platform/w-ui-angular';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import {NavBarComponent_} from "./nav-bar/nav-bar.component";
import {RouterModule} from "@angular/router";

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        NavBarComponent_
    ],
    imports: [
        CommonModule,
        WUiAngularModule,
        RouterModule
    ],
    exports: [HeaderComponent,
        FooterComponent, NavBarComponent_],
    providers: [],
    bootstrap: [],
})
export class PageModule { }
