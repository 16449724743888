import { Injectable } from "@angular/core";
import {
    DialogRef, DialogService as KendoDialogService, DialogSettings as KendoDialogSettings
} from "@progress/kendo-angular-dialog";

export interface DialogSettings extends KendoDialogSettings { }

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor(private _dialog: KendoDialogService) { }

    open(settings: DialogSettings) {
        const dialog: DialogRef = this._dialog.open({
            ...settings,
            width: settings.width ?? 350,
            height: settings.height ?? 215,
            minWidth: settings.minWidth ?? 350,
        });
        return {
            close: () => {
                dialog.close();
            },
            result: dialog.result
        }
    }

    openDialog(settings: any, dialogComponent: any) {
        const dialog: DialogRef = this._dialog.open({
            ...settings,
            title: undefined,
            width: settings.width ?? 400,
            minWidth: settings.minWidth ?? 350,
            content: dialogComponent,
            cssClass: 'confirm-dialog-wrapper',
            minHeight: settings.minHeight ?? 215,
            maxHeight: settings.maxHeight ?? 600
        });

        const instance = dialog.content.instance;
        instance.settings = settings;

        return {
            close: () => {
                dialog.close();
            },
            result: dialog.result
        }
    }
}