import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import {throwError, Observable, Subscriber, retryWhen, timer, mergeMap} from 'rxjs';
import { catchError} from 'rxjs/operators';
import {AppUtilService} from "./app-util.service";
import {BaseRequest, Constant, ResponseStatus, BaseResponse} from "../model";
import { ToastNotificationService } from './toast-notification.service';


export interface DataMapper {
    request?: (item: any) => any;
    response?: (item: any) => any;
}

export class DateMapper implements DataMapper {
    constructor(private appUtil: AppUtilService) { }

    request = (item: any) => {
        if (item instanceof Date) {
            item = this.appUtil.formatDate(item, true, Constant.DATE_TIME_FORMAT);
        }
        else {
            for (const key of Object.keys(item)) {
                const value = item[key];
                if (value instanceof Date) {
                    item[key] = this.appUtil.formatDate(value, true, Constant.DATE_TIME_FORMAT);
                }
            }
        }
        return item;
    }

    response = (item: any) => {
        if (this.appUtil.isDateString(item)) {
            item = this.appUtil.parseDate(item);
        }
        else {
            for (const key of Object.keys(item)) {
                const value = item[key];
                if (this.appUtil.isDateString(value)) {
                    item[key] = this.appUtil.parseDate(value);
                }
            }
        }
        return item;
    }
}

@Injectable({
    providedIn: 'root'
})
export class RemoteService {
    private readonly retryNbr: number = 3;
    private readonly retryInterval: number = 5000;//5 seconds
    handleError: (errorMsg: any) => Observable<never> = this.defaultHandleError.bind(this);
    dateMapper: DateMapper;
    constructor(protected httpClient: HttpClient, protected appUtil: AppUtilService
                , protected toastService: ToastNotificationService) {
        this.dateMapper = new DateMapper(appUtil);
    }
    processResponseData(resp: BaseResponse, mapper?: DataMapper | undefined) {
        resp.data = this.processData(resp.data, mapper?.response);
    }

    processData(data: any, map: ((item: any) => any) | undefined): any | undefined {
        if (map) {
            let temp: any = data;
            if (Array.isArray(temp)) {
                data = temp.map(item => map(item));
            }
            else if (temp) {
                data = map(temp);
            }
        }

        return data;
    }

    processRequestData(req: BaseRequest, mapper?: DataMapper) {
        req.data = this.processData(req.data, mapper?.request);
    }
    private isRequestBase(obj: any): obj is BaseRequest{
        return 'data' in obj;
    }

    public post<T = any>(url: string, payload: any | BaseRequest, params?: HttpParams , mapper?: DataMapper
                         , retryNbr: number = this.retryNbr, showError: boolean = true): Observable<BaseResponse<T>> {
        const options =  params? {params: params}: {};
        const reqPayload: BaseRequest = this.isRequestBase(payload)? payload: { data: payload };
        this.processRequestData(reqPayload, mapper);

        const that = this;
        return new Observable<BaseResponse<T>>(subscriber => {
            // debugger;
            const retryPipe = this.retryPipe(retryNbr);
            const observable = this.httpClient.post<BaseResponse<T>>(url, reqPayload, options).pipe(
                retryPipe.retryWhen.bind(that),
                retryPipe.catchError.bind(that)
            );

            const processResponse = this.processResponse(subscriber, mapper, showError);
            observable.subscribe({
                next: processResponse.success.bind(this),
                error: processResponse.error.bind(this)
            });
        });
    };
    public put<T = any>(url: string, payload: any | BaseRequest, params?: HttpParams | any, mapper?: DataMapper
        , retryNbr: number = this.retryNbr, showError: boolean = true): Observable<BaseResponse<T>> {
        const options =  params? {params: params}: {};
        const reqPayload: BaseRequest = this.isRequestBase(payload)? payload: { data: payload };
        this.processRequestData(reqPayload, mapper);

        const that = this;
        return new Observable<BaseResponse<T>>(subscriber => {
            const retryPipe = this.retryPipe(retryNbr);
            const observable = this.httpClient.put<BaseResponse<T>>(url, reqPayload, options).pipe(
                retryPipe.retryWhen.bind(that),
                retryPipe.catchError.bind(that)
            );

            const processResponse = this.processResponse(subscriber, mapper, showError);
            observable.subscribe({
                next: processResponse.success.bind(this),
                error: processResponse.error.bind(this)
            });
        });
    };

    private retryPipe(retryNbr: number) {
        const that = this;
        return {
            retryWhen: retryWhen(
                mergeMap((error, retryAttempt) => {
                    if (++retryAttempt >= retryNbr || this.noRetry(error)) {
                        return throwError(error);
                    }
                    return timer(that.retryInterval);
                })
            ),
            catchError: catchError((error: Error | HttpErrorResponse) => that.handleError(error))
        }
    }

    private processResponse(subscriber: Subscriber<any>, mapper?: DataMapper, showError?: any) {
        return {
            success: (resp: any) => {
                if (resp && resp.status === ResponseStatus.SUCCESS) {
                    this.processResponseData(resp, mapper);
                    subscriber.next(resp);
                }
                else {
                    if (showError) {
                        this.handleError({ error: resp });
                    }
                    subscriber.error({ error: resp });
                }
                subscriber.complete();
            },
            error: (error: any) => {
                subscriber.error(error);
                subscriber.complete();
            }
        }
    }

    public get<T = any>(url: string, params?: HttpParams | any, mapper?: DataMapper, retryNbr: number = this.retryNbr
                        , showError: boolean = true): Observable<BaseResponse<T>> {
        const options = params? {params: params}: {};

        const that = this;
        return new Observable<BaseResponse<T>>(subscriber => {
            const retryPipe = this.retryPipe(retryNbr);
            const observable = this.httpClient.get<BaseResponse<T>>(url, options).pipe(
                retryPipe.retryWhen.bind(that),
                retryPipe.catchError.bind(that)
            );

            const processResponse = this.processResponse(subscriber, mapper, showError);
            observable.subscribe({
                next: processResponse.success.bind(this),
                error: processResponse.error.bind(this)
            });
        });
    }
    public delete<T = any>(url: string, params?: HttpParams, mapper?: DataMapper, retryNbr: number = this.retryNbr
        , showError: boolean = true): Observable<BaseResponse<T>> {
        const options = params? {params: params}: {};

        const that = this;
        return new Observable<BaseResponse<T>>(subscriber => {
            const retryPipe = this.retryPipe(retryNbr);
            const observable = this.httpClient.delete<BaseResponse<T>>(url, options).pipe(
                retryPipe.retryWhen.bind(that),
                retryPipe.catchError.bind(that)
            );

            const processResponse = this.processResponse(subscriber, mapper, showError);
            observable.subscribe({
                next: processResponse.success.bind(this),
                error: processResponse.error.bind(this)
            });
        });
    }

    private processBytesResponse(subscriber: Subscriber<any>) {
        return {
            success: (resp: any) => {
                subscriber.next(resp);
                subscriber.complete();
            },
            error: (error: any) => {
                subscriber.error(error);
                subscriber.complete();
            }
        }
    }

    private getBytesOptions(mimeType: string, params?: HttpParams): any {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', mimeType);
        headers = headers.set('Content-Type', 'application/json');
        return {
            headers: headers,
            responseType: 'blob',
            params: params
        }
    }

    public postBytes<T>(url: string, payload: any | BaseRequest, params?: HttpParams, mimeType: string = "application/pdf", mapper?: DataMapper, retryNbr: number = this.retryNbr): Observable<any> {
        const reqPayload: BaseRequest = this.isRequestBase(payload)? payload: { data: payload };
        const headerOptions = this.getBytesOptions(mimeType, params);

        this.processRequestData(reqPayload, mapper);
        const that = this;
        return new Observable<T>(subscriber => {
            const retryPipe = this.retryPipe(retryNbr);
            const observable = this.httpClient.post<T>(url, reqPayload, headerOptions).pipe(
                retryPipe.retryWhen.bind(that),
                retryPipe.catchError.bind(that)
            );

            const processResponse = this.processBytesResponse(subscriber);
            observable.subscribe({
                next:processResponse.success.bind(this),
                error: processResponse.error.bind(this)
            });
        });
    };

    public getBytes<T>(url: string, params?: HttpParams, mimeType: string = "application/pdf", retryNbr: number = this.retryNbr): Observable<T> {
        const that = this;
        const headerOptions = this.getBytesOptions(mimeType, params);

        return new Observable<T>(subscriber => {
            const retryPipe = this.retryPipe(retryNbr);
            const observable = this.httpClient.get<T>(url, headerOptions).pipe(
                retryPipe.retryWhen.bind(that),
                retryPipe.catchError.bind(that)
            );

            const processResponse = this.processBytesResponse(subscriber);
            observable.subscribe({
                next: processResponse.success.bind(this),
                error: processResponse.error.bind(this)
            });
        });
    }
    private isBusinessError(errorMsg: any): boolean{
        return !!errorMsg.error && !!errorMsg.error.data;
    }
    private noRetry(errorMsg: any): boolean{
        return this.isBusinessError(errorMsg) || errorMsg.status === 403 || errorMsg.status === 401;
    }
    defaultHandleError(errorMsg: any){
        if (this.isBusinessError(errorMsg)) {
            this.toastService.show({
                content: errorMsg.error.data,
                type: {style: ResponseStatus.getStatusStyle(errorMsg.error.status)}
                , hideAfter: 5000
            });
        }
        else if (errorMsg.status === 403 || errorMsg.status === 401) {
            this.toastService.show({ content: "You are not allow to access the current resource."
                , type: {style: 'error'}, hideAfter: 5000
            });
        }
        else {
            this.toastService.show({ content: "Unexpected exception, please contact the application administrator."
                , type: {style: 'error'}, hideAfter: 5000
            });
        }
        return throwError(errorMsg);
    };
}
