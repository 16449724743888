import {Pipe, PipeTransform} from "@angular/core";
import { AppUtilService } from "../services/app-util.service";
@Pipe({name: "wDate"})
export class WDatePipe implements PipeTransform{
    constructor(private appUtil: AppUtilService) {
    }
    transform(value: any, withTime?: boolean, format?: string ): any {
        return !value? "" : withTime?
              format? this.appUtil.formatDate(value, withTime, format): this.appUtil.formatDate(value, withTime)
            : format? this.appUtil.formatDate(value, false, format): this.appUtil.formatDate(value);
    }
}