import {Injectable} from "@angular/core";
import {AppUtilService} from "./app-util.service";
import {BaseResponse} from "../model";
import {RemoteService} from "./remote.service";
import {profileURL} from "./api-endpoints";
import {Observable, tap, map} from "rxjs";
import {Router} from "@angular/router";
import {HttpParams} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class SecurityUtilService{
    static readonly READ_WRITE: string = "READ/WRITE";
    static readonly READ_ONLY: string = "READ";
    private _userProfile: any;
    private _userMenus: Array<any> = [];
    public set userProfile(value: any){
        this._userProfile = value;
    }
    public set userMenus(value: Array<any>){
        this._userMenus = value;
    }
    constructor(private remoteService: RemoteService, private appUtil: AppUtilService, private router: Router) {
    }
    private populateUserProfile(): Observable<BaseResponse> {
        const url: string = `${profileURL}/profile`;
        return this.remoteService.get<any>(url).pipe(tap(resp => {
            this._userProfile = resp.data;
            const others:any = resp.others;
            this._userMenus = others?.userMenus?.subMenus??[];
        }));
    }
    isInGroup(...groupNames: string[]): Promise<boolean> {
        return this.isInGroups(groupNames);
    }
    isInGroups(groupNames: string[]): Promise<boolean> {
        return new Promise(resolve => {
            if(!this._userProfile){
                this.populateUserProfile().subscribe(resp => {
                    resolve(this.checkGroups(groupNames));
                });
            }
            else{
                resolve(this.checkGroups(groupNames));
            }
        });
    }
    getUserProfile(): Promise<any> {
        return new Promise(resolve => {
            if(!this._userProfile){
                this.populateUserProfile().subscribe(resp => {
                    resolve(this._userProfile);
                });
            }
            else{
                resolve(this._userProfile);
            }
        });
    }
    private checkGroups(groups: string[]): boolean{
        let retValue = false;

        if (groups.length > 0 && this._userProfile && this._userProfile.assignedGroups && this._userProfile.assignedGroups.length > 0) {
            const useGroups: any[] = this._userProfile.assignedGroups;
            retValue = groups.some(group => useGroups.some(userGroup => userGroup.groupName == group))
        }

        return retValue;

    }

    isInRole(...appFuncCds: string[]): Promise<boolean> {
        return this.isInRoles(appFuncCds);
    }
    isInRoles(roles: string[]): Promise<boolean> {
        return new Promise(resolve => {
            if(!this._userProfile){
                this.populateUserProfile().subscribe(resp => {
                    resolve(this.checkRoles(roles));
                });
            }
            else{
                resolve(this.checkRoles(roles));
            }
        });
    }
    private checkRoles(roles: string[]): boolean{
        let retValue = false;

        if (roles.length > 0 && this._userProfile && this._userProfile.roles && this._userProfile.roles.length > 0) {
            const userRoles: any[] = this._userProfile.roles;
            retValue = roles.some(role => userRoles.some(userRole => userRole.appFuncCd == role))
        }

        return retValue;

    }
    isInPermission(role: string, ...permissionCds: string[]): Promise<boolean>{
        return new Promise(resolve => {
            if(!this._userProfile){
                this.populateUserProfile().subscribe(resp => {
                    resolve(this.checkPermissions(role, permissionCds));
                });
            }
            else{
                resolve(this.checkPermissions(role, permissionCds));
            }
        });
    }
     checkPermissions(role: string, permissionCds: string[]) {
        let retValue = false;

        const userRoles: any[] = this._userProfile?.roles || [];
        const userRole = userRoles.find(userRole => userRole.appFuncCd == role);
        if (permissionCds && permissionCds.length > 0 && userRole && userRole.permissions && userRole.permissions.length > 0) {
            const rolePermissions: any[] = userRole.permissions;
            retValue = permissionCds.some(permission => rolePermissions.some(rolePermission => rolePermission.permissionCd.includes(permission)))
        }
        const isImpersonateInProdEnv = this._userProfile.impersonator === true && this._userProfile.prodEnv === true;
        return retValue && !isImpersonateInProdEnv;
    }

    checkReadOnlyPermissions(role: string, permissionCds: string[]) {
        let retValue = false;

        const userRoles: any[] = this._userProfile?.roles || [];
        const userRole = userRoles.find(userRole => userRole.appFuncCd == role);
        if (permissionCds && permissionCds.length > 0 && userRole && userRole.permissions && userRole.permissions.length > 0) {
            const rolePermissions: any[] = userRole.permissions;
            retValue = permissionCds.some(permission => rolePermissions.some(rolePermission => rolePermission.permissionCd.includes(permission)))
        }
        return retValue;
    }

    isEditable(urlPath?: string): Promise<boolean>{
        return this.isUserInPermission(urlPath, SecurityUtilService.READ_WRITE);
    }

    isUserInPermission(urlPath?: string, ...permissionCds: string[]): Promise<boolean>{
        return new Promise(resolve => {
            if(!this._userProfile || !this._userMenus){
                this.populateUserProfile().subscribe(resp => {
                    resolve(this.checkPagePermissions(urlPath ? urlPath : this.router.url, permissionCds));
                });
            }
            else{
                resolve(this.checkPagePermissions(urlPath ? urlPath : this.router.url, permissionCds));
            }
        });
    }
    private checkPagePermissions(urlPath: string, permissionCds: string[]): boolean{
        let hasPermission: boolean = false;
        if(!urlPath) return hasPermission;

        urlPath = urlPath.trim();
        urlPath = urlPath.startsWith("/")? urlPath.slice(1): urlPath;
        urlPath = urlPath.startsWith("(") ? urlPath.slice(1): urlPath;
        urlPath = urlPath.endsWith(")") ? urlPath.slice(0, -1): urlPath;

        const menuItem = this.appUtil.find(this._userMenus, "urlPath", urlPath, "subMenus",
                item => urlPath.startsWith(item.urlPath));
        if(menuItem){
            const assignedMenuRoles: any[] = menuItem.roles;
            hasPermission = assignedMenuRoles.some(menuRole => this.checkPermissions(menuRole, permissionCds));
        }
        return hasPermission;
    }
    isUserInRole(urlPath: string): Promise<boolean>{
        return new Promise(resolve => {
            if(!this._userProfile || !this._userMenus){
                this.populateUserProfile().subscribe(resp => {
                    resolve(this.checkPageRole(urlPath));
                });
            }
            else{
                resolve(this.checkPageRole(urlPath));
            }
        });
    }
    private checkPageRole(urlPath: string): boolean{
        let hasRole: boolean = false;
        if(!urlPath) return hasRole;

        urlPath = urlPath.startsWith("/")? urlPath.slice(1): urlPath;

        const menuItem = this.appUtil.find(this._userMenus, "urlPath", urlPath, "subMenus",
            item => urlPath.startsWith(item.urlPath));

        if(menuItem){
            const assignedMenuRoles: any[] = menuItem.roles;
            hasRole = this.checkRoles(assignedMenuRoles);
        }
        return hasRole;
    }
    isInternalUser(): Promise<boolean> {
        return new Promise(resolve => {
            if(!this._userProfile){
                this.populateUserProfile().subscribe(resp => {
                    resolve(this._userProfile.internalInd === "Y");//internalInd is intl_ind
                });
            }
            else{
                resolve(this._userProfile.internalInd === "Y");//internalInd is intl_ind
            }
        });
    }

    getPageComponents(urlPath?: string, componentName?: string): Observable<any[]> {
        let params: HttpParams = new HttpParams();
        if(urlPath){
            params = params.append('urlPath', urlPath);
        }
        if(componentName){
            params = params.append('componentName', componentName);
        }
        return this.remoteService.get(`${profileURL}/component/menu`, params)
            .pipe(
                map(resp => resp.data)
            );
    }
}