import {  NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppStoreModule } from './store/app-store.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EntityDataModule } from '@ngrx/data';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { RouterModule } from '@angular/router';
import { WUiAngularModule } from '@williams-ui-platform/w-ui-angular';
import { HomeComponent } from './home/home.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { CommonModule } from '@angular/common';
import { DownloadComponent } from './downloads/download/download.component';
import { SiteMapComponent } from './site-map/site-map/site-map.component';
import { LocationsComponent } from './locations/locations.component';
import { IndexOfCustomersComponent } from './indexofcustomers/index-of-customers.component';
import { ContactsComponent } from './contacts/contacts.component';
import { HtmlViewerComponent } from './html-viewer/html-viewer.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { PageModule } from './pages/page.module';
import { ErrorComponent } from './error/error.component';
import {MapComponent} from "./map/map.component";
import {ContactsGuard} from "./gaurds/contacts.gaurd";
@NgModule({
  declarations: [AppComponent,
    HomeComponent,
    SiteMapComponent,
    IndexOfCustomersComponent,
    LocationsComponent,
    ContactsComponent,
    DownloadComponent,
    SiteMapComponent,
    PdfViewerComponent,
    HtmlViewerComponent,
    ErrorComponent,
    MapComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    LayoutModule,
    PageModule,
    WUiAngularModule,
    NgxExtendedPdfViewerModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot({}),
    environment.production ? [] : StoreDevtoolsModule.instrument(),
    AppStoreModule,
    RouterModule.forRoot(
      [
        {
          path: '',
          redirectTo: 'home',
          pathMatch: 'full'
        },
        {
          path: 'home',
          pathMatch : "full",
          component: HomeComponent
        },
        {
          path: 'capacity',
          loadChildren: () => import('./capacity/capacity.module').then(m => m.CapacityModule)
        },
        {
          path:'capacityhtml/:id',
          pathMatch : "full",
          component:HtmlViewerComponent
        },
        {
          path: 'gas-quality',
          loadChildren: () => import('./gas-quality/gas-quality.module').then(m => m.GasQualityModule)
        },
        {
          path:'gas-qualityhtml/:id',
          pathMatch : "full",
          component:HtmlViewerComponent
        },
        {
          path: 'downloads',
          pathMatch : "full",
          component: DownloadComponent
        },
        {
          path: 'indexofcustomers',
          pathMatch : "full",
          component: IndexOfCustomersComponent

        },
        {
          path: 'notices',
          loadChildren: () => import('./notices/notices.module').then(m => m.NoticesModule)
        },
        {
          path:'noticeshtml/:id',
          pathMatch : "full",
          component:HtmlViewerComponent
        },
      
        {
          path: 'locations',
          pathMatch : "full",
          component: LocationsComponent
        },
        {
          path: 'contacts',
          pathMatch : "full",
          component: ContactsComponent,
          //canActivate: [ContactsGuard]
        },
        {
          path: 'downloads',
          pathMatch : "full",
          component: DownloadComponent
        },
        {
          path: 'site-map',
          pathMatch : "full",
          component: SiteMapComponent
        },
        {
          path:'standard-of-conduct/:id',
          pathMatch : "full",
          component:HtmlViewerComponent
        },
        {
          path:'postedImbalances',
          pathMatch : "full",
          component:HtmlViewerComponent
        },
        {
          path:'transactionalReporting/:id',
          pathMatch : "full",
          component:HtmlViewerComponent
        },
        {
          path:'search',
          pathMatch : "full",
          component:HtmlViewerComponent
        },
        {
          path:'pdf-viewer',
          pathMatch : "full",
          component:PdfViewerComponent
        },
        {
          path:'tariff/:id',
          pathMatch : "full",
          component:HtmlViewerComponent
        },
        {
          path : "error",
          pathMatch : "full",
          component: ErrorComponent
        },
        {
          path : "system-map",
          pathMatch : "full",
          component: MapComponent
        },
        {
          path : "**",
          redirectTo : '/error'
        }
      ],
      { initialNavigation: 'enabledBlocking' }
    ),
 
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
