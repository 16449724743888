import {Component, EventEmitter, forwardRef, Input, Optional, Output} from "@angular/core";
import {ControlContainer, NG_VALUE_ACCESSOR} from "@angular/forms";
import {
    DateInputFillMode,
    DateInputFormatPlaceholder,
    DateInputRounded,
    DateInputSize, TimePickerIncrementalSteps
} from "@progress/kendo-angular-dateinputs";
import { BaseAccessor } from "../base/base-accessor";


@Component({
    selector: 'williams-ui-platform-timepicker',
    templateUrl: './timepicker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimepickerComponent),
            multi: true
        }
    ]
})
export class TimepickerComponent extends BaseAccessor {
    @Input() adaptiveMode: 'none' | 'auto' = 'none';
    @Input() allowCaretMode: boolean = false;
    @Input() autoSwitchKeys: string[] = [];
    @Input() autoSwitchParts: boolean = true;
    @Input() cancelButton: boolean = true;
    @Input() disabled: boolean = false;
    @Input() enableMouseWheel: boolean = true;
    @Input() fillMode: DateInputFillMode = 'solid';
    @Input() format: string = 'HH:mm';
    @Input() formatPlaceholder: DateInputFormatPlaceholder = 'narrow';
    @Input() incompleteDateValidation: boolean = false;
    @Input() max!: Date;
    @Input() min!: Date;
    @Input() nowButton: boolean = true;
    @Input() placeholder: string = 'HH:mm';
    @Input() rangeValidation: boolean = false;
    @Input() readOnly: boolean = false;
    @Input() readOnlyInput: boolean = false;
    @Input() rounded: DateInputRounded = 'full';
    @Input() size: DateInputSize = 'medium';
    @Input() steps: TimePickerIncrementalSteps = {};
    @Input() subtitle: string = '';
    @Input() title: string = '';
    @Input() value: Date = new Date();
    @Input() showErrorMsg: boolean = false;
    @Input() classList = '';
    @Output() valueChange: EventEmitter<Date> = new EventEmitter<Date>();

    constructor(@Optional() public override controlContainer: ControlContainer) {
        super(controlContainer);
    }

    valueChangeHandler(event: Date) {
        this.valueChange.emit(event);
    }
}