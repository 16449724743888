import {Pipe, PipeTransform} from "@angular/core";
import { AppUtilService } from "../services";

@Pipe({name: "wPhone"})
export class WPhonePipe implements PipeTransform{
    constructor(private appUtil: AppUtilService) {
    }
    transform(value: any): any {
        return this.appUtil.formatPhone(value);
    }
}