import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory
} from '@ngrx/data';
import {map, Observable, tap} from 'rxjs';
import { CONTACTS } from '../entity-meta-data/entity-metadata';
import { StaticResponse } from '../model/base-response';
import { ContactsInterface } from '../model/contacts.interface';
import {contactsURL, htmlUrl, pdfUrl} from '../../../api-endpoints';

@Injectable({ providedIn: 'root' })
export class ContactsService extends EntityCollectionServiceBase<ContactsInterface> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private http: HttpClient) {
    super(CONTACTS,serviceElementsFactory);
  }

  getContactsData(): Observable<any> {
    return  this.http.get<StaticResponse<Array<ContactsInterface>>>(contactsURL)
      .pipe(map((res: StaticResponse<Array<ContactsInterface>>) => {
        return res;
    }));
  }

  getDocumentData(location: string, filePath: string): Observable<string> {
    const url = pdfUrl.replace('{location}', location) + filePath;
    const headers = new HttpHeaders({
      'accept': 'text/csv'
    });
    const params: any = {};
    params.fileLocation = '';
    return this.http.get(url, { headers, params, observe: 'response', responseType: 'text' })
        .pipe(
            map((res: HttpResponse<string>) => {
              return res.body ?? '';
            })
        );
  }
}