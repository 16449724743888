import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Optional,
  Output,
  ViewChild
} from "@angular/core";
import {
  DropDownFillMode,
  DropDownRounded,
  DropDownSize,
  DropDownTreeComponent,
  PreventableEvent
} from "@progress/kendo-angular-dropdowns";
import {TreeItem, TreeViewFilterSettings} from "@progress/kendo-angular-treeview";
import {Subject, takeUntil} from "rxjs";
import {ControlContainer, NG_VALUE_ACCESSOR} from "@angular/forms";
import { BaseAccessor } from "../base/base-accessor";
import { PopupSettings } from "../combobox/models";


// Component for dropdown tree
// Support for Kendo's flat data binding: items are passed to
// the component as a flat list and the parentIdField associates objects to their
// parent objects to construct the tree.
// Data and parentId are required.
@Component({
  selector: 'williams-ui-platform-dropdown-tree',
    templateUrl: './dropdown-tree.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownTreeComponent),
      multi: true,
    },
  ],
})
export class DropdownTreeComponent extends BaseAccessor implements AfterViewInit, OnDestroy {
  @ViewChild('dropdownTree', {static: true}) dropdownTree!: DropDownTreeComponent;
  @Input() clearButton: boolean = true;
  @Input() data: any[] = [];
  @Input() dataItem: any;
  @Input() disabled = false;
  @Input() fillMode: DropDownFillMode = 'solid';
  @Input() filterable = false;
  @Input() filterSettings: TreeViewFilterSettings = {};
  @Input() itemDisabled: (item: object, index: string) => boolean = () => false;
  @Input() listHeight = 200;
  @Input() loading = false;
  @Input() loadOnDemand = true;
  @Input() parentIdField = 'parentId';
  @Input() placeholder = '';
  @Input() popupSettings: PopupSettings = {};
  @Input() readonly = false;
  @Input() rounded: DropDownRounded = 'none';
  @Input() size: DropDownSize = 'medium';
  @Input() textField!: string;
  @Input() title: any="";
  @Input() valueField!: string;
  @Input() value: any;
  @Input() valuePrimitive = true;
  @Input() showOptionsOnFocus = true;
  @Input() showErrors = false;

  @Output() close: EventEmitter<PreventableEvent> = new EventEmitter<PreventableEvent>();
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() nodeCollapse: EventEmitter<TreeItem> = new EventEmitter<TreeItem>();
  @Output() nodeExpand: EventEmitter<TreeItem> = new EventEmitter<TreeItem>();
  @Output() blur: EventEmitter<void> = new EventEmitter<void>();
  @Output() focus: EventEmitter<any> = new EventEmitter<any>();
  @Output() open: EventEmitter<PreventableEvent> = new EventEmitter<PreventableEvent>();
  @Output() opened: EventEmitter<any> = new EventEmitter<any>();
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  private destroy$ = new Subject<void>();

  constructor(@Optional() public override controlContainer: ControlContainer) {
    super(controlContainer);
    this.itemDisabled = this.itemDisabled.bind(this);
  }

  ngAfterViewInit(): void {
    if(this.showOptionsOnFocus) {
      this.dropdownTree.onFocus.pipe(
          takeUntil(this.destroy$)
      ).subscribe(() => {
        this.dropdownTree.togglePopup(true);
      })
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClose(e: PreventableEvent): void {
    this.close.emit(e);
  }

  onClosed(e: any): void {
    this.closed.emit(e);
  }

  onFilterChange(value: string): void {
      this.filterChange.emit(value);
  }

  onNodeCollapse(e: TreeItem): void {
      this.nodeCollapse.emit(e);
  }

  onNodeExpand(e: TreeItem): void {
      this.nodeExpand.emit(e);
  }

  onBlur(): void {
      this.blur.emit();
  }

  onFocus(e: any): void {
      this.focus.emit(e);
  }

  onOpen(e: PreventableEvent): void {
      this.open.emit(e);
  }

  onOpened(e: any): void {
      this.opened.emit(e);
  }

  onValueChange(e: any): void {
    this.valueChange.emit(e);
  }

  blurTree() {
    this.dropdownTree.blur();
  }

  focusTree() {
    this.dropdownTree.focus();
  }

  focusItemAt(index: string) {
    this.dropdownTree.focusItemAt(index)
  }

  reset() {
    this.dropdownTree.reset();
  }

  toggle(open?: boolean) {
    this.dropdownTree.toggle(open);
  }
}